<template>
  <div>
    <h1 class="campaigns-title text-left">{{ $t("general.campaigns") }}</h1>
    <div class="filters-container text-left">
      <TrekioInput id="search" v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>
      <TrekioSelect multiple v-model="filter.countries" selectAllOption :isAllSelected="filter.countries.length === allCountriesInCampaigns.length" :label="$t('general.countries')"
        :placeholder="$t('general.countries')" :text="countriesSelectText"
      >
        <TrekioSelectOption v-for="country in allCountriesInCampaigns" :key="country.code" :value="country.code">{{ country[countryLocalization] }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect :label="$t('inputLabels.state')" v-model="filter.state" :text="filter.state ? filter.state == 'PUBLISHED' ? $t('general.publishedPlural') : $t('general.hiddenPlural') : $t('general.all')">
        <TrekioSelectOption :value="null">{{ $t('general.all') }}</TrekioSelectOption>
        <TrekioSelectOption value="PUBLISHED">{{ $t('general.publishedPlural') }}</TrekioSelectOption>
        <TrekioSelectOption value="NOT_PUBLISHED">{{ $t('general.hiddenPlural') }}</TrekioSelectOption>
      </TrekioSelect>
    </div>
    <p class="text-left" v-if="filteredCampaigns && filteredCampaigns.length === 0 && !isLoading">{{ $t("filter.noResults") }}</p>
    <CampaignList v-if="filteredCampaigns && !hasFilterChanged" :campaigns="filteredCampaigns" :topPriority="highestPriorityValue" @refetchCampaigns="refetchCampaigns"/>
    <div v-if="isLoading || showMoreEnabled" class="page-loading-container">
      <div v-if="isLoading" class="loader-1 center paging-loading"><span></span></div>
      <TrekioButton v-if="showMoreEnabled && !isLoading" secondary @click="showMore">{{ $t("general.loadMore") }}</TrekioButton>
    </div>
  </div>
</template>

<script>

import CampaignList from "@/components/admin/CampaignList";
import {CAMPAIGNS_SHORT_LIST_ADMIN, CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED} from "@/api/graphql/query/CampaignQuery";
import sharedUtils from '@/utils/sharedUtils.js'

const SINGLE_PAGE_SIZE = 10

export default {
  name: "CampaignListPage",
  components: {
    CampaignList,
  },

  data: function () {
    return {
      countries: [],
      campaigns: null,
      filteredCampaigns: [],
      cbAllChecked: false,

      filter: {
        searchString: "",
        state: null,
        countries: [],
      },
      pageSize: {
        page: 0,
        size: SINGLE_PAGE_SIZE,
        sortAttribute: "LENGTH_OF_STAY",
        sortDirection: "ASC"
      },
      showMoreEnabled: true,
      hasFilterChanged: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.getLoggingOut == false) this.$store.commit('setCampaignsFilter', this.filter)
    next()
  },

  mounted: function () {
    this.$apollo.queries.campaigns.refetch();
    if (this.$store.getters.getCampaignsFilter != null) this.filter = this.$store.getters.getCampaignsFilter
  },

  methods: {
    refetchCampaigns() {
      this.$apollo.queries.filteredCampaigns.refetch()
    },
    showMore () {
      this.pageSize.page++

      this.$apollo.queries.filteredCampaigns.fetchMore({
        variables: {
          filter: this.filter,
          pageSize: this.pageSize
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.filteredCampaigns.length < SINGLE_PAGE_SIZE) {
            this.showMoreEnabled = false
          }

          return {
            filteredCampaigns: [
              ...previousResult.filteredCampaigns,
              ...fetchMoreResult.filteredCampaigns,
            ],
          }
        },
      })
    },
    toggleAll() {
      this.filter.countries = this.cbAllChecked ? this.allCountriesInCampaigns.map(country => country.code) : []
    },
  },

  computed: {
    countryLocalization() {
      return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech" 
    },
    isLoading() {
      return this.$apollo.queries.filteredCampaigns.loading
    },
    allCountriesInCampaigns() {
      if (!this.campaigns) return []
      let allCountries = []
      this.campaigns.forEach (campaign => {
        campaign.travelTips.forEach(travelTip => {
          travelTip.itineraryDays.forEach(day => {
            day.itineraryItems.forEach(item => {
              item.countries.forEach(country => {
                if (!allCountries.some(value => value.code === country.code)) {
                  allCountries.push(country)
                }
              })
            })
          })
        })
      })
      return allCountries.sort((a, b) => {
        return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
      })
  },
    highestPriorityValue() {
      if (this.campaigns) {
      const maxPriority = Math.max(...this.campaigns.map(c => c.priorityOrder), 0);
      return maxPriority
      }
      return 1
    },
    translateCountry() {
      let foundValue = this.countriesInCampaigns.find(value => value.code === this.filter.countries[0]);
      if (foundValue) return foundValue[this.countryLocalization];
      return this.$t("general.countries")
    },
    countriesSelectText() {
      if (this.filter.countries.length === this.allCountriesInCampaigns.length) {
        return this.$t("general.allFemAndLifeless")
      }
      if (this.filter.countries.length > 0) {
        const countriesNames = []
        this.allCountriesInCampaigns.forEach(country => {
          if (this.filter.countries.includes(country.code)) {
            countriesNames.push(country[this.countryLocalization])
          }
        })
        return countriesNames.join(", ")
      }
      return this.$t("general.countries")
    }
  },

  apollo: {
    campaigns: {
      query: CAMPAIGNS_SHORT_LIST_ADMIN,
    },
    filteredCampaigns: {
      query: CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED,

      variables() {
        return {
          filter: {
            searchString : this.filter.searchString,
            state: this.filter.state,
            countries: this.filter.countries
          },
          pageSize: {
            page: 0,
            size: SINGLE_PAGE_SIZE,
            sortAttribute: "LENGTH_OF_STAY",
            sortDirection: "ASC"
          },
        } 
      },
      result({data}) {
        if (data.filteredCampaigns.length < SINGLE_PAGE_SIZE) {
          this.showMoreEnabled = false
        }
        this.hasFilterChanged = false
      },
      fetchPolicy: 'cache-and-network',
    },
  },

  watch: {
    filter: {
      handler(newValue, oldValue) {
        this.pageSize.page = 0
        this.hasFilterChanged = true
        this.showMoreEnabled = true
      }, 
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">

.page-loading-container {
  max-width: 1100px;
  margin-top: 30px
}

.filters-container {
  padding-bottom: 50px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));
}


</style>